// import router from "@/router";
import axios from "axios";
import { Message } from "element-ui";

let isJumping = false; // 标记是否正在跳转页面

const instance = axios.create({
  // baseURL: '/api',
  baseURL: "https://device.resmartcharge.com", // 线上环境
  // baseURL: "https://device.develop.resmartcharge.com", // 测试环境
  // baseURL: "http://api.device.alpha.resmartcharge.com", // 开发环境
  timeout: 30000,
});

instance.interceptors.request.use(
  (config) => {
    // console.log("请求拦截器---");
    // //判断是否有token，如果有，则请求头中携带上token
    let token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = token;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
// 添加响应拦截器
instance.interceptors.response.use(
  (res) => {
    let res_data = res.data;
    if (res_data.code != 200) {
      // token过期
      if (res_data.code === 101) {
        Message.error(res_data.msg);
        localStorage.removeItem("token");
        localStorage.removeItem("total_shots_count");
        localStorage.removeItem("name");
        localStorage.removeItem("avatar");

        if (!isJumping) {
          window.location.href = "#/login";
        }

        isJumping = true;
      } else if (res_data.code === 102) {
        console.log("执行了这2");
        Message.error(res_data.msg);
      } else if (res_data.code === 100) {
        console.log("执行了这3");
        Message.error(res_data.msg);
      } else {
        console.log("执行了这4");
        Message.error(res_data.msg);
      }
      // return false
    }
    return res_data;
    // return false;
  },
  (err) => {
    if (err.response && err.response.data && err.response.data.error) {
      Element.Message.error(err.response.data.error);
    } else {
      Message.error("网络请求错误");
    }
    return Promise.reject(err);
  }
);

//导出
export default instance;
